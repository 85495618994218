<header>
  <div class="row">
    <div class="col-6 col-md-3">
      <div class="navbar">
        
            <img src="/assets/images/tfc_logo_black.png" />
        
        </div>
    </div>

    <div class="col-6 col-md-9">
      <div class="navbar-container pull-right">
        <nav class="navbar navbar-expand-xl navbar-dark bg-dark">
          
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          </button>
          
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="navbar">
              <ul class="navbar-nav mr-auto" *ngIf="userSecLvl !== 'admin'">
                  <li class="nav-item active">
                      <a class="nav-link" routerLink="/home">Home</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link active" routerLink="/categories">Products</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" routerLink="/categories/238">Find Your School</a>
                  </li>
                  
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle active" data-toggle="dropdown">
                      <span class="nav-label">Mounted Swatches</span> <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                      <div *ngFor="let doc of basicDocs">
                        <li class="nav-item mounted-item">
                          <a href="{{ imageSource }}/{{doc.fileName}}" target="_blank">{{doc.name}}</a>
                        </li>
                      </div>
                      <li class="nav-item mounted-item"><hr></li>
                      <div *ngFor="let doc of interiorDocs">
                        <li class="nav-item mounted-item">
                          <a href="{{ imageSource }}/{{doc.fileName}}" target="_blank">{{doc.name}}</a>
                        </li>
                      </div>
                      <li class="nav-item mounted-item"><hr></li>
                      <div *ngFor="let doc of materialDocs">
                        <li class="nav-item mounted-item">
                          <a href="{{ imageSource }}/{{doc.fileName}}" target="_blank">{{doc.name}}</a>
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" routerLink="/resources">Instructor Resources</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" routerLink="/student-resources">Student Resources</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" routerLink="/videos">Textile Videos</a>
                  </li>
                  <li class="nav-item" *ngIf="userName === null"><a class="nav-link active" routerLink="/login">Login</a></li>
                  <li class="nav-item dropdown" *ngIf="userName !== null">
                    <a class="nav-link active">
                      <a class="dropdown-toggle bb-brand" data-toggle="dropdown">
                        Hello {{ userName }} <b class="caret"></b>
                      </a>
                      <ul class="dropdown-menu">
                          <li class="nav-item user-item"><a (click)="logoutTFC()">Logout</a></li>
                      </ul>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link active" routerLink="/shopping-cart">
                      <i class="fa fa-shopping-cart"></i>
                      <span class='badge badge-warning' id='lblCartCount' *ngIf="cartCount > 0"> {{cartCount}} </span>
                    </a>
                  </li>
              </ul>
              <ul class="navbar-nav" *ngIf="userSecLvl === 'admin'">
                <li class="nav-item active"><a class="nav-link" routerLink="/admin">Dashboard</a></li>
                <li class="nav-item active"><a class="nav-link" routerLink="/admin/orders">Orders</a></li>
                <li class="nav-item active"><a class="nav-link" routerLink="/admin/users">Users</a></li>
                <li class="nav-item active"><a class="nav-link" routerLink="/admin/products">Products</a></li>
                <li class="nav-item active"><a class="nav-link" routerLink="/admin/categories">Categories</a></li>
                <li class="nav-item active"><a class="nav-link" routerLink="/admin/media">Textile Videos</a></li>
                <li class="nav-item dropdown pull-right" *ngIf="userName !== null">
                  <a class="nav-link active">
                    <a class="dropdown-toggle bb-brand" data-toggle="dropdown">
                      Hello {{ userName }} <b class="caret"></b>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="nav-item user-item"><a (click)="logoutTFC()">Logout</a></li>
                    </ul>
                  </a>
                </li>

              </ul>
              <!--
              <form class="d-flex">
                  <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                  <button class="btn btn-outline-primary" type="submit">Search</button>
              </form>
            -->
            </div>
          </div>
        </nav>
    </div>
    </div>
  </div>
</header>
