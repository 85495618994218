<!-- <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="col-sm-12">
              <h5 class="card-title">Textile Videos</h5>
              <p class="card-text">Coming soon!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <p-dataView #dv [value]="videos">
        <ng-template pTemplate="header">
          <h3 class="card-title">Videos</h3>
          <p><strong>Total Videos: </strong>{{ (videos$ | async)?.length }}</p>
        </ng-template>
        <ng-template pTemplate="item" let-video let-index="index">
          <div class="card">
            <div class="card-body">
              <div class="col-sm-12">
                <h5 class="card-title">{{ video.subject }}</h5>
                <p class="card-text">{{ video.description }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
  