import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

import { Category } from './../../models/category.model';

import { AuthenticationService } from './../../services/auth/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from './../../services/site/category.service';
import { ShoppingCartService } from './../../services/site/shopping-cart.service';
import { UserService } from './../../services/site/user.service';
import { DocumentResponse } from 'src/app/models/document-response.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  userName: string;
  userSecLvl: string;
  cartCount: number;
  currentUserSubscription: Subscription;
  currentCartCountSubscription: Subscription;
  currentUserSecLvlSubscription: Subscription;
  imageSource = `${environment.awsUrl}/documents`;
  categories: Category[];
  isAdmin = false;

  basicDocs: DocumentResponse[] = [];
  interiorDocs: DocumentResponse[] = [];
  materialDocs: DocumentResponse[] = [];

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly categoryService: CategoryService,
    private readonly cartService: ShoppingCartService,
    private readonly toastrService: ToastrService,
    private readonly userService: UserService
  ) { 
    this.currentUserSubscription = this.authService.currentUser.subscribe(data => {
      this.userName = data;
    });
    this.currentUserSecLvlSubscription = this.authService.currentUserSecLvl.subscribe(data => {
      this.userSecLvl = data;
    });
    this.currentCartCountSubscription = this.cartService.cartCount.subscribe(data => {
      this.cartCount = data;
    });
  }

  ngOnInit(): void {
    console.log('userSecLvl: ' + this.userSecLvl);
    if (this.userSecLvl !== 'admin') {
      this.getCategories();
      this.getBasicDocuments();
      this.getInteriorDocuments();
      this.getMaterialDocuments();
    }
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
    this.currentUserSecLvlSubscription.unsubscribe();
    this.currentCartCountSubscription.unsubscribe();
  }

  logoutTFC() {
    this.authService.logout();
    sessionStorage.clear();
    this.router.navigate(["home"]);
    this.toastrService.success('You have successfully logged out');
  }

  getCategories() {
    this.categoryService.getCategories()
      .subscribe(data => {
        this.categories = [];

        data.forEach(d => {
          if (d.imagePath === undefined || d.imagePath == null || d.imagePath.length === 0) {
            d.imagePath = 'noimage.jpg';
          }
          const item = { id: d.id, name: d.name, imagePath: d.imagePath, slug: '', children: d.children, parent: d.parent };
          this.categories.push(item);
        });
      });
  }

  getBasicDocuments() {
    
    this.userService.getDocuments(1)
      .subscribe(data => {
        data.forEach(item => {
          this.basicDocs.push(item);
        });

      });
  }

  getInteriorDocuments() {
    this.userService.getDocuments(2)
      .subscribe(data => {
        data.forEach(item => {
          this.interiorDocs.push(item);
        });
      });
  }

  getMaterialDocuments() {
    this.userService.getDocuments(3)
      .subscribe(data => {
        data.forEach(item => {
          this.materialDocs.push(item);
        });
      });
  }

}
